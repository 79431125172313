import React from 'react';
import styles from './App.module.css'

function App() {
  return (
      <>
        <header className={styles.mainHeader}></header>
        <section className={styles.hero}>
            <div className={styles.text}>
                <p className={styles.flourish}></p>
                <h1>Task Strategy</h1>
                <p>Coming Soon</p>
            </div>
        </section>
      </>
  );
}

export default App;
